.active.fade-right {
    animation: fade-right 1s ease-in;
    width: '40%';
    display: 'flex';
    justify-content: 'space-around';
    align-items: 'center';
    flex-wrap: 'wrap';
    color:aliceblue
    
  }
  
  @keyframes fade-right {
    0% {
      transform: translateY(-50pY);
      opacity: 0;
     
    }
    100% {
      transform: translateY(0);
      opacity: 1;

    }
  }